<template>
	<form class="form" @submit.stop.prevent="onSubmit">
		<div class="card card-custom">
			<div class="card-header border-0 py-5">
				<h3 class="card-title align-items-start flex-column">
					<span class="card-label font-weight-bolder text-dark">{{ $t('VIEWS.Account.title') }}</span>
				</h3>
			</div>
			<div class="card-body">
				<vue-loaders-square-spin v-if="isLoading" color="#000"></vue-loaders-square-spin>
				<div class="row" v-if="!isLoading">
					<div class="col-12 col-md-6">
						<div class="form-group">
							<label>{{ $t('VIEWS.Account.firstName') }}</label>
							<input type="text" class="form-control" v-model="form.first_name" name="first_name" />
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="form-group">
							<label>{{ $t('VIEWS.Account.lastName') }}</label>
							<input type="text" class="form-control" v-model="form.last_name" name="last_name" />
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="form-group">
							<label>{{ $t('VIEWS.Account.mail') }}</label>
							<input type="text" class="form-control" v-model="form.email" name="email" />
						</div>
					</div>
					<div class="col-12">
						<hr />
					</div>
					<div class="col-12 my-4">
						<h4>Neues Passwort festlegen</h4>
					</div>
					<div class="col-12 col-md-6">
						<div class="form-group">
							<label>Neues Passwort eingeben</label>
							<input type="text" class="form-control" v-model="form.password" name="password" />
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="form-group">
							<label>Passwort erneut eingeben</label>
							<input type="text" class="form-control" v-model="form.password_confirmation" name="password_confirmation" />
						</div>
					</div>
					<div class="col-12">
						<span class="text-dark-75">{{ $t('VIEWS.Account.passwordSafety') }}</span
						><br />
						<span class="text-dark-75">{{ $t('VIEWS.Account.passwordInfo') }}</span
						><br />
						<span class="text-dark-75">{{ $t('VIEWS.Account.passwordExample') }}</span>
					</div>
				</div>
			</div>
			<div class="card-footer d-flex justify-content-between">
				<b-button type="submit" variant="success" :disabled="isUpdating">
					{{ $t('GENERAL.Forms.save') }}
					<b-spinner small v-if="isUpdating" class="ml-2"></b-spinner>
				</b-button>
				<b-button type="button" variant="danger" :disabled="isDeleting" v-on:click.prevent="onDelete">
					<i class="fas fa-exclamation-triangle"></i>
					{{ $t('GENERAL.Forms.delete') }}
					<b-spinner small v-if="isDeleting" class="ml-2"></b-spinner>
				</b-button>
			</div>
		</div>
	</form>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { mapGetters } from 'vuex';
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
import { LOGOUT } from '@/core/services/store/auth.module';
import { PRINOR_TOASTS } from '@/core/services/toast.service';
import { PRINOR_PROCESSES } from '@/core/services/actions.service';

export default {
	name: 'Profile',
	data() {
		return {
			isLoading: true,
			isUpdating: false,
			isDeleting: false,
			organizationId: '',
			form: {
				first_name: '',
				last_name: '',
				email: '',
				password: '',
				password_confirmation: '',
			},
		};
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('MENU.Account.title') }]);
		this.isLoading = false;

		this.form.first_name = this.currentUser.first_name;
		this.form.last_name = this.currentUser.last_name;
		this.form.email = this.currentUser.email;
	},
	methods: {
		onSubmit() {
			this.isUpdating = true;
			this.update().then(data => {
				if (data.success === true) {
					this.$store.dispatch(LOGOUT).then(() => this.$router.push({ name: 'login' }));
				}
			});
		},
		onDelete() {
			let that = this;
			Swal.fire({
				title: this.$t('VIEWS.Profile.delete.title'),
				text: this.$t('VIEWS.Profile.delete.confirmation'),
				icon: 'warning',
				confirmButtonText: this.$t('GENERAL.Forms.delete'),
				showCancelButton: true,
				cancelButtonText: this.$t('GENERAL.Buttons.cancel'),
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary',
				},
				heightAuto: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			}).then(result => {
				if (result.isConfirmed) {
					that.delete().then(data => {
						if (data.success === true) {
							this.$store.dispatch(LOGOUT).then(() => this.$router.push({ name: 'login' }));
						}
					});
				}
			});
		},
		update() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.put('auth/user/update', this.form).then(data => {
					this.isUpdating = false;
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							PRINOR_TOASTS.save.confirmation(this);
							resolve(data.data);
						} else {
							PRINOR_TOASTS.save.error(this, data.data.error);
						}
					}
				});
			});
		},
		delete() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.delete('user/delete').then(data => {
					PRINOR_PROCESSES.reset();
					resolve(data);
				});
			});
		},
	},
	computed: {
		...mapGetters(['currentUser']),
	},
};
</script>

<style scoped></style>
